



























































































































































































































































































































// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import baseUserContextPage from "./baseUserContextPage";
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";
import { pagedList } from "@/models/pagedList";
import { categoryModel } from "@/models/categoryModel";
import { productModel, productType } from "@/models/productModel";
import { CustomerInfoModel } from "@/models/customerModels";
import { productSearchResult } from "@/models/productSearchModel";
import { facetModel } from "@/models/facetModel";
import domainService from "@/services/domainService";

import eventHub from "../eventHub";
import _, { forEach } from "lodash";
import { optionModel, optionValueModel, optionAndSingleValueModel } from "@/models/optionModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
var Qs = require("qs");

@Component({
	computed: {
		...mapGetters(["getSearchLayout"])
	},
	methods: {
		...mapMutations(["setSearchLayout"])
	}
})
export default class catalogPage extends baseUserContextPage {
	setSearchLayout!: (layout: string | null) => void;
	getSearchLayout!: string;
	showFilterModal: boolean = false;
	clickedOptionCode: string = null;

	get urlFilters(): any {
		var instance = this;
		var params = instance.$route.query;

		return params.filters != undefined && params.filters != null ? Qs.parse(params.filters) : {};
	}

	_searchKey: string = "";
	_facetsKey: string = "";
	categories: categoryModel[] = [];
	filters: optionModel[] = [];
	showCustomerModal: boolean = false;
	showAddToCartModal: boolean = false;
	isLoadingProducts: boolean = false;
	isLoadingListini: boolean = false;
	isLoadingFacets: boolean = false;
	list: pagedList<productModel> = new pagedList();
	facets: facetModel[] = [];
	facetsForCount: facetModel[] = [];
	selectedProduct: productModel = null;
	//  categoryFacets: facetModel[] = [];
	get categoryFacets(): facetModel[] {
		if (this.facets == null) return [];
		var cf = this.facets.find(x => x.code == "CategoryId");
		return cf == null || cf.values == null ? [] : this.facets;
	}
	get resultLayout(): string {
		return this.getSearchLayout;
	}
	setLayout(layout: string) {
		this.setSearchLayout(layout);
	}
	searchParams: any = {
		pageSize: 12,
		pageIndex: 0,
		orderField: "name",
		direction: "ASC",
		search: null,
		categoryId: null,
		filters: {}
	};
	@Watch("$route", { deep: true })
	onRouteChange(to, from) {
		var instance = this;
		// if (to.query.categoryId != from.query.categoryId)
		//   instance.filters.forEach(f =>
		//     f.values.forEach(o => (o.selected = false))
		//   );
		// debugger;
		//if (to.query.search != from.query.search)
		instance.filters.forEach(f => f.values.forEach(o => (o.selected = false)));
		instance.setQueryParams(to.query);
		this.fetchProducts();
	}
	get productsRows() {
		var instance = this;
		return _.chunk(instance.list.items, 3);
	}
	get productsList() {
		var instance = this;
		return instance.list.items;
	}
	get showCustomerDescription(): boolean {
		return this.categoryId != null && (this.isUserB2BAdmim || this.isUserRefEmployee || this.isUserEmployee);
	}
	get isCustomerSelected(): boolean {
		return this.getCurrentCustomer != null;
	}
	get isLoading() {
		// return this.isLoadingCategories || this.isLoadingProducts;
		return this.isLoadingProducts;
	}
	get categoryId(): number {
		// var instance = this;
		// if (instance.$route.query && instance.$route.query.categoryId)
		//   return instance.$route.query.categoryId as any;
		// return null;

		return this.$route && this.$route.params && this.$route.params.categoryId ? Number.parseInt(this.$route.params.categoryId) : null;
	}
	get categorySelected(): categoryModel {
		var instance = this;
		if (instance.categories == null || instance.categoryId == null) return new categoryModel();
		var c = instance.getSelectedCategory(instance.categories);

		return c == null ? new categoryModel() : c;
	}
	get subCategoriesSelected(): categoryModel[] {
		var instance = this;
		if (instance.categoryId == null) return instance.categories || [];

		var c = instance.getSelectedCategory(instance.categories);
		return c == null ? instance.categories || [] : c.subCategories || [];
	}

	getSelectedCategory(categories: categoryModel[]): categoryModel {
		var instance = this;
		if (instance.categoryId == null) return null;
		if (categories == null || categories.length == 0) return null;
		else {
			for (var i = 0; i < categories.length; i++) {
				let c = categories[i];
				if (c.id == instance.categoryId) return c;
				c = instance.getSelectedCategory(c.subCategories);
				if (c != null) return c;
			}
			return null;
		}
	}
	getSelectedCategoryParent(category: categoryModel): categoryModel {
		var instance = this;
		if (instance.categoryId == null) return null;

		var categories = category == null ? this.categories : category.subCategories;

		if (categories == null || categories.length == 0) return null;

		var c = categories.find(x => x.id == instance.categoryId);
		if (c != null) return category;
		else {
			for (var i = 0; i < categories.length; i++) {
				let csub = categories[i];
				var parent = instance.getSelectedCategoryParent(csub);
				if (parent != null) return parent;
			}
		}
		return null;
	}
	get categoriesForFilter(): categoryModel[] {
		var instance = this;
		if (instance.categoryId == null) return instance.categories || [];

		var categories = instance.getCategoriesForFilter(instance.categories);
		return categories == null ? instance.categories || [] : categories.filter(x => x.id != instance.categoryId) || [];
	}
	getCategoriesForFilter(categories: categoryModel[]): categoryModel[] {
		var instance = this;
		if (instance.categoryId == null) return null;
		if (categories == null || categories.length == 0) return null;
		else {
			for (var i = 0; i < categories.length; i++) {
				let c = categories[i];
				if (c.id == instance.categoryId) return categories;
				let cats = instance.getCategoriesForFilter(c.subCategories);
				if (cats != null) return cats;
			}
			return null;
		}
	}
	get categoriesFigliOFoglie(): categoryModel[] {
		var instance = this;
		if (instance.categoryId == null) return instance.categories || [];

		var categories = instance.getCategoriesFigliOFoglie(instance.categories);
		return categories == null ? instance.categories || [] : categories;
	}
	getCategoriesFigliOFoglie(categories: categoryModel[]): categoryModel[] {
		var instance = this;
		if (instance.categoryId == null) return null;
		if (categories == null || categories.length == 0) return null;
		else {
			for (var i = 0; i < categories.length; i++) {
				let c = categories[i];
				if (c.id == instance.categoryId) {
					return (c.subCategories || []).length > 0 ? c.subCategories : categories;
				}
				let cats = instance.getCategoriesFigliOFoglie(c.subCategories);
				if (cats != null) return cats;
			}
			return null;
		}
	}
	get categoryFilterTitle(): string {
		var instance = this;
		var c = instance.categorySelected;
		if (c == null) return "Catalogo";
		if (c.subCategories != null && c.subCategories.length > 0) return instance.getCategoryPropery(c, "name");

		var parent = instance.getSelectedCategoryParent(null);
		return parent != null && parent.id != null ? instance.getCategoryPropery(parent, "name") : "Catalogo";
		// return 'Categorie';
	}
	get categoryTitle(): string {
		var instance = this;
		var c = instance.categorySelected;
		if (c == null) return "Catalogo";
		return instance.getCategoryPropery(c, "name");
	}
	getCategoryPropery(category: categoryModel, property: string) {
		if (category == null || category.locales == null) return "";
		var l = category.locales["it-IT"];
		return l == null ? "" : l[property];
	}
	// isCategorySelected(c: categoryModel): boolean {
	//   var instace = this;
	//   return (
	//     c != null &&
	//     (c.id == instace.categoryId ||
	//       this.isAnyChildrenCategorySelected(c.subCategories))
	//   );
	// }

	// isAnyChildrenCategorySelected(categories: categoryModel[]): boolean {
	//   var instance = this;
	//   if (instance.categoryId == null) return false;
	//   if (categories == null || categories.length == 0) return false;
	//   else {
	//     return (
	//       categories.find(
	//         x =>
	//           x.id == instance.categoryId ||
	//           this.isAnyChildrenCategorySelected(x.subCategories)
	//       ) != null
	//     );
	//   }
	// }
	get showAvtiveFiltersRow(): boolean {
		var instance = this;
		return instance.activeFilters.length > 0;
	}
	get activeFilters() {
		var instance = this;
		if (instance.filters == null) return [];
		return instance.filters.filter(x => x.values != null && x.values.find(o => o.selected) != null);
	}
	get showFilters(): boolean {
		var instance = this;

		var fs = instance.filtersValid;
		return (fs != null && fs.length > 0) || instance.showAvtiveFiltersRow;
	}
	get filtersValid(): facetModel[] {
		var instance = this;
		if (instance.facets == null || instance.facets.length == 0) return [];

		var f = instance.facets.filter(
			x =>
				x.code != "CategoryId" &&
				x.code != "TOP" &&
				x.values != null &&
				Object.keys(x.values).find(v => v != null && v != "" && v != "EMPTY_STRING" && x.values[v] != null && x.values[v] > 0) != null
		);
		return f;
	}
	getSelectedOptions(filter: optionModel) {
		var instance = this;
		if (filter.values == null) return [];
		return filter.values.filter(x => x.selected);
	}
	getSearchParams() {
		var copy = Object.assign({}, this.searchParams);
		copy.customerId = this.getCurrentCustomerId;
		copy.categoryId = this.categoryId;
		return copy;
	}
	updateQueryParams(): void {
		var instance = this;
		var q = instance.getQueryParams();
		// debugger;
		instance.$router.push({
			name: "catalog",
			params: { categoryId: (instance.categoryId || "").toString() },
			query: q
		});
	}
	getQueryParams(): any {
		var instance = this;

		var hasFilters = instance.activeFilters.length > 0;
		if (hasFilters) {
			instance.searchParams.filters = {};
			instance.activeFilters.forEach(x => {
				var selected = [];
				x.values.forEach(o => {
					if (o.selected) {
						selected.push(o.code);
					}
				});
				if (selected.length > 0) instance.searchParams.filters[x.code] = selected;
			});
		} else {
			instance.searchParams.filters = {};
		}

		var f = hasFilters ? Qs.stringify(instance.searchParams.filters) : undefined;
		var q = {
			page: instance.searchParams.pageIndex,
			search: instance.searchParams.search,
			// categoryId: instance.searchParams.categoryId,
			filters: f == null || f == "" ? undefined : f
		};
		return q;
	}
	setQueryParams(params: any) {
		var instance = this;

		instance.searchParams.pageIndex = params.page;
		instance.searchParams.search = params.search;
		// instance.searchParams.categoryId = params.categoryId;
		instance.searchParams.filters = params.filters != undefined && params.filters != null ? Qs.parse(params.filters) : {};

		instance.filters.forEach(x => {
			var selFilter = instance.searchParams.filters[x.code];
			if (x.values != null)
				x.values.forEach(o => {
					o.selected = selFilter != null && selFilter.indexOf(o.code) != -1;
				});
		});
	}
	pageChanged(page: number) {
		var instance = this;
		instance.searchParams.pageIndex = page;
		instance.updateQueryParams();
	}
	// onSearch() {
	//   var instance = this;
	//   instance.searchParams.pageIndex = 0;
	//   instance.updateQueryParams();
	// }
	onSelectCategoryBreadcrumb(c: categoryModel) {
		var instance = this;
		if (c == null) {
			instance.$router.push({ path: `/categories` });
		} else if (c.subCategories != null && c.subCategories.length > 0) {
			instance.$router.push({ path: `/categories/${c.id}` });
		} else instance.doSelectCategory(c, false);
	}
	onSelectCategoryFilter(c: categoryModel) {
		var instance = this;

		instance.doSelectCategory(c, true);
		// instance.doSelectCategory(c, false);
	}

	doSelectCategory(c: categoryModel, preserveSearch: boolean) {
		var instance = this;
		// debugger;
		if (c == null) {
			instance.$router.push({ path: `/categories` });
		} else {
			// instance.searchParams.categoryId = c == null ? null : c.id;
			// instance.searchParams.pageIndex = 0;
			// if (preserveSearch == false) {
			//   instance.searchParams.search = null;
			//   instance.searchParams.filters = {};
			//   instance.filters.forEach(f =>
			//     f.values.forEach(o => (o.selected = false))
			//   );
			// }
			// instance.updateQueryParams();
			if (preserveSearch == false) {
				instance.$router.push({
					name: "catalog",
					params: c != null && c.id != null ? { categoryId: c.id.toString() } : null
				});
			} else {
				var q = instance.getQueryParams();
				q.page = 0;
				instance.$router.push({
					name: "catalog",
					params: c != null && c.id != null ? { categoryId: c.id.toString() } : null,
					query: q
				});
			}
		}
	}
	onResetSearch() {
		var instance = this;
		instance.searchParams.search = null;
		instance.searchParams.pageIndex = 0;
		instance.filters.forEach(f => f.values.forEach(o => (o.selected = false)));
		instance.updateQueryParams();
	}
	onSelectFilter(f: optionAndSingleValueModel) {
		var instance = this;
		// instance.searchParams.categoryId = c == null ? null : c.id;
		// instance.searchParams.pageIndex = 0;

		// if (instance.searchParams.filters == null)
		//   instance.searchParams.filters = {};

		// if (instance.searchParams.filters.hasOwnProperty(f.option.code) == false) {
		//   instance.searchParams.filters[f.option.code] = [f.value];
		// } else {
		//   if (instance.searchParams.filters[f.option.code].indexOf(f.value) == -1) {
		//     instance.searchParams.filters[f.option.code].push(f.value);
		//   } else {
		//     instance.searchParams.filters[
		//       f.option.code
		//     ] = instance.searchParams.filters[f.option.code].filter(
		//       x => x != f.value.code
		//     );

		//   }
		// }

		// var filter = instance.filters.find(x => x.code == f.key);
		// if (filter != null) {
		//   var o = filter.values.find(x => x.code == f.value);
		//   if (o != null) o.selected = !o.selected;
		// }
		f.value.selected = !f.value.selected;

		instance.searchParams.pageIndex = 0;
		instance.updateQueryParams();
	}
	onCustomerSelectedFromProducCard(customer: CustomerInfoModel) {
		var instance = this;
		instance.fetchListini();
	}
	onSelectCustomer(customer: CustomerInfoModel) {
		var instance = this;
		instance.setCurrentCustomer(customer);
		instance.showCustomerModal = false;
		// this.fetchProducts();
		instance.fetchListini().then(res => {
			if (instance.selectedProduct != null) instance.showAddToCartModal = true;
		});
	}
	onSelectProduct(p: productModel) {
		var instance = this;
		instance.selectedProduct = p;
		if (instance.getCurrentCustomer == null) {
			instance.showCustomerModal = true;
		} else {
			instance.showAddToCartModal = true;
		}
	}
	get colSpan(): number {
		return 6;
	}
	get skus(): string[] {
		var instance = this;
		if (instance.list == null || instance.list.items == null || instance.list.items.length == 0) return [];

		var skus = [];

		instance.list.items.forEach(p => {
			skus.push(p.sku);
			if (p.skus != null)
				p.skus.forEach(c => {
					skus.push(c.sku);
				});
		});

		return skus;
	}
	fetchListini(): Promise<boolean> {
		var instance = this;
		instance.isLoadingListini = true;

		return new Promise((resolve, reject) => {
			if (instance.skus != null && instance.skus.length > 0) {
				axios
					.post<sisasListiniModel[]>("/product/listini", {
						customerId: this.getCurrentCustomerId,
						skus: instance.skus
					})
					.then(res => {
						if (res.data) {
							instance.list.items.forEach(p => {
								p.listini = res.data.filter(x => x.articolo == p.sku);
								if (p.skus != null)
									p.skus.forEach(c => {
										c.listini = res.data.filter(x => x.articolo == c.sku);
									});
							});
						}
						instance.isLoadingListini = false;
						resolve(true);
					})
					.catch(error => {
						console.error(error);
						instance.isLoadingListini = false;
						reject(error);
					});
			} else {
				resolve(false);
			}
		});

		// if (instance.skus != null && instance.skus.length > 0) {
		//   axios
		//     .post<sisasPimListiniModel[]>("/product/listini", {
		//       customerId: this.getCurrentCustomerId,
		//       skus: instance.skus
		//     })
		//     .then(res => {
		//       if (res.data) {
		//         instance.list.items.forEach(p => {
		//           p.listini = res.data.filter(x => x.articolo == p.sku);
		//           if (p.skus != null)
		//             p.skus.forEach(c => {
		//               c.listini = res.data.filter(x => x.articolo == c.sku);
		//             });
		//         });
		//       }
		//       instance.isLoadingListini = false;
		//     })
		//     .catch(error => {
		//       console.error(error);
		//       instance.isLoadingListini = false;
		//     });
		// }
	}
	fetchProducts(): void {
		var instance = this;
		instance.isLoadingProducts = true;
		// if (this.categoryId == null) {
		//  instance.list = new pagedList();
		//  instance.facets = [];
		//  instance.isLoadingProducts = false;
		// } else {
		var p = instance.getSearchParams();
		instance._searchKey = Date.now().toString();
		p.searchKey = instance._searchKey;
		p.addFacets = p.categoryId != null;
		axios
			.get<productSearchResult>("/product/search", {
				params: p
				// paramsSerializer: function(params) {
				//   // Qs is already included in the Axios package
				//   return Qs.stringify(params, {
				//     arrayFormat: "none"
				//   });
				// }
			})
			.then(res => {
				if (res.data && res.data.searchKey == instance._searchKey) {
					instance.list = res.data && res.data.list ? res.data.list : new pagedList();
					instance.facets = res.data && res.data.facets ? res.data.facets : [];
					instance.facetsForCount = res.data && res.data.facetsForCount ? res.data.facetsForCount : [];
					// if (p != null && p.customerId != null)
					instance.fetchListini();
					instance.isLoadingProducts = false;
					// instance.setFiltriSeRicercaPerSku();
				}
			})
			.catch(error => {
				console.error(error);
				instance.isLoadingProducts = false;
			});
		// }
		// instance.fetchFacets();
	}
	fetchFacets(): void {
		var instance = this;
		instance.isLoadingFacets = true;
		// if (this.categoryId == null) {
		//  instance.list = new pagedList();
		//  instance.facets = [];
		//  instance.isLoadingProducts = false;
		// } else {
		var p = instance.getSearchParams();
		instance._facetsKey = Date.now().toString();
		p.searchKey = instance._facetsKey;
		axios
			.get<productSearchResult>("/product/facets", {
				params: p
				// paramsSerializer: function(params) {
				//   // Qs is already included in the Axios package
				//   return Qs.stringify(params, {
				//     arrayFormat: "none"
				//   });
				// }
			})
			.then(res => {
				if (res.data && res.data.searchKey == instance._facetsKey) {
					instance.facets = res.data && res.data.facets ? res.data.facets : [];
					instance.facetsForCount = res.data && res.data.facetsForCount ? res.data.facetsForCount : [];

					instance.isLoadingFacets = false;
					// instance.setFiltriSeRicercaPerSku();
				}
			})
			.catch(error => {
				console.error(error);
				instance.isLoadingFacets = false;
			});
	}

	beforeMount() {
		var instance = this;
		domainService.getCategories().then(res => (this.categories = res == null ? [] : res));
		domainService.getOptinFilters().then(res => {
			if (res == null) instance.filters = [];
			else {
				instance.filters = res.map(x => {
					var selFilter = instance.searchParams.filters[x.code];
					var f = new optionModel();
					f.code = x.code;
					f.name = x.name;
					f.type = x.type;
					f.languageId = x.languageId;
					f.values =
						x.values == null
							? []
							: x.values.map(y => {
									var o = new optionValueModel();
									o.code = y.code;
									o.name = y.name;
									o.languageId = y.languageId;
									o.selected = selFilter != null && selFilter.indexOf(y.code) != -1;
									return o;
							  });
					return f;
				});
			}
		});
	}
	mounted() {
		var instance = this;
		instance.setQueryParams(instance.$route.query);
		instance.fetchProducts();
		eventHub.$on(eventHub.events.CURRENT_CUSTOMER_CHANGED, instance.fetchListini);
	}
	beforeDestroy() {
		var instance = this;
		eventHub.$off(eventHub.events.CURRENT_CUSTOMER_CHANGED, instance.fetchListini);
	}

	get specificoId(): number {
		var instance = this;
		if (instance.searchParams.search && instance.list.totalCount == 1 && instance.list.items[0].type == productType.Configurable) {
			var p = instance.list.items[0];
			var params = instance.searchParams.search.split(" ") || [];

			var children = (p.skus || []).filter(x => (params as Array<string>).find(p => p == x.sku) != null);
			if (children && children.length == 1) {
				var p = children[0];
				return p.id;
			}
		}
		return null;
	}
	setFiltriSeRicercaPerSku() {
		var instance = this;
		if (instance.searchParams.search && instance.list.totalCount == 1 && instance.list.items[0].type == productType.Configurable) {
			var p = instance.list.items[0];
			var params = instance.searchParams.search.split(" ") || [];

			var children = (p.skus || []).filter(x => (params as Array<string>).find(p => p == x.sku) != null);
			if (children && children.length == 1) {
				var p = children[0];
				// gli sku figli sono prodotti light e non ho le varianti caricate
				// if (p.variantsValues) {
				//   console.log(p.variantsValues);
				//   for (const property in p.variantsValues) {
				//     console.log(`${property}: ${p.variantsValues[property]}`);
				//     debugger;
				//   }
				// }
			}
		}
	}
	get firstElementIndex(): number {
		if (this.list == null) return 0;
		var currentPage = this.list.currentPage || 1;
		var pageSize = this.list.pageSize || 0;
		return 1 + (currentPage - 1) * pageSize;
	}
	get lastElementIndex(): number {
		if (this.list == null || this.list.items == null) return 0;
		return this.firstElementIndex + this.list.items.length - 1;
	}
	get showFilterSearch(): boolean {
		return this.$route.query != null && this.$route.query.search != null && this.$route.query.search != "";
	}
	onFilterModalOpen() {
		// debugger;
	}
	openFilterMoldal(option: optionModel) {
		// debugger;
		this.clickedOptionCode = option == null ? null : option.code;
		this.showFilterModal = true;
	}
}
